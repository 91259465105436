:root {
  /**
    * @tokens Colors
    * @presenter Color
    */
  --seccl-color-black: #000;
  --seccl-color-white: #fff;
  --seccl-color-brand-primary: #1d1739;
  --seccl-color-brand-secondary: #f85c88;
  --seccl-color-grey-1: #f6f5f7;
  --seccl-color-grey-2: #c9c6cc;
  --seccl-color-grey-3: #9d9aa2;
  --seccl-color-grey-4: #73707b;
  --seccl-color-grey-5: #4c4856;
  --seccl-color-grey-6: #272433;
  --seccl-color-positive: #219653;
  --seccl-color-warning: #ff8446;
  --seccl-color-warning-yellow: #fb0;
  --seccl-color-yellow: #fff500;
  --seccl-color-negative: #da2f29;
  --seccl-color-info: #0573a9;
  --seccl-color-accent: #0573a9;
  --seccl-app-background: #f4f3f5;

  /**
    * @tokens Font sizes
    * @presenter FontSize
    */
  --seccl-font-size-xxsmall: 12px;
  --seccl-font-size-xsmall: 14px;
  --seccl-font-size-small: 16px;
  --seccl-font-size-medium: 18px;
  --seccl-font-size-large: 20px;
  --seccl-font-size-xlarge: 24px;
  --seccl-font-size-xxlarge: 32px;
  --seccl-font-size-xxxlarge: 40px;

  /**
    * @tokens Font families
    * @presenter FontFamily
    */
  --seccl-primary-font: 'BrownLLWeb', sans-serif;
  --seccl-secondary-font: 'BrownLLWeb', sans-serif;

  /**
    * @tokens Spacings
    * @presenter Spacing
    */
  --seccl-spacing-xxxsmall: 0.375rem;
  --seccl-spacing-xxsmall: 0.5rem;
  --seccl-spacing-xsmall: 0.625rem;
  --seccl-spacing-small: 0.75rem;
  --seccl-spacing-medium: 1rem;
  --seccl-spacing-large: 1.25rem;
  --seccl-spacing-xlarge: 1.5rem;
  --seccl-spacing-xxlarge: 2rem;
  --seccl-spacing-xxxlarge: 2.5rem;
  --seccl-spacing-xxxxlarge: 3rem;

  /**
    * @tokens Border radius
    * @presenter BorderRadius
    */
  --seccl-border-radius-small: 5px;
  --seccl-border-radius-medium: 8px;
  --seccl-border-radius-large: 10px;

  /**
    * @tokens Font weights
    * @presenter FontWeight
    */
  --seccl-font-weight-light: 300;
  --seccl-font-weight-regular: 400;
  --seccl-font-weight-medium: 500;
  --seccl-font-weight-bold: 600;
  --seccl-font-weight-xbold: 700;

  /**
    * @tokens Box shadow
    * @presenter Shadow
    */
  --seccl-box-shadow: 0 4px 24px 0 rgb(0 0 0 / 4%);
}
:root {
  --seccl-form-padding: var(--seccl-spacing-large);
  --seccl-form-input-max-width: 350px;
  --seccl-form-step-list-max-width: 350px;
  --seccl-form-border: 1px solid var(--seccl-color-grey-2);
  --seccl-review-section-max-width: 650px;
  --default-form-max-width: 1100px;
}
/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */
/* stylelint-disable custom-property-pattern */
:root {
  /* Main colors */
  --mui-palette-error-main: var(--seccl-color-negative);
  --mui-palette-primary-main: var(--seccl-color-brand-primary);
  --mui-palette-primary-dark: var(--seccl-color-brand-primary-dark);

  /* Text */
  --mui-palette-text-primary: var(--seccl-color-grey-5);
  --mui-palette-text-secondary: var(--seccl-color-grey-5);
  --mui-palette-text-disabled: var(--seccl-color-grey-3);
  --mui-palette-action-active: var(--seccl-color-brand-primary);

  /* Actions */
  --mui-palette-action-hover: var(--seccl-color-grey-1);
  --mui-palette-action-disabledOpacity: 0.5;
  --mui-palette-action-focusOpacity: 0.5;

  /* Used for drop down highlights and selection */
  --mui-palette-action-activeChannel: 139 210 245;
  --mui-palette-primary-mainChannel: 139 210 245;
  --mui-palette-action-focus: var(--seccl-color-grey-1);
  --mui-palette-action-selectedOpacity: 0.2;
  --mui-palette-action-hoverOpacity: 0.2;

  /* Shadows */
  --mui-shadows-1: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --mui-shadows-8: 0px 4px 4px rgba(0, 0, 0, 0.25);

  /* Paper */
  --mui-palette-background-paper: var(--seccl-color-white);

  /* App bar */
  --mui-palette-primary-contrastText: var(--seccl-color-white);
  --mui-zIndex-appBar: 100;
  --mui-zIndex-modal: 102;

  /* Flyout */
  --mui-zIndex-drawer: 101;
}
body * {
  box-sizing: border-box;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

:root {
  --seccl-spinner-speed: 1s;
  --seccl-spinner-color: var(--seccl-color-brand-secondary);
}

.seccl-spinner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--seccl-spinner-color);
}

.seccl-spinner[data-fill-container='true'] {
  position: absolute;
  inset: 0;
}

.seccl-spinner-inner {
  animation: spin var(--seccl-spinner-speed) linear infinite;
  width: max-content;
  height: max-content;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.seccl-spinner-label {
  font-family: var(--seccl-primary-font);
  margin-top: var(--seccl-spacing-small);
  font-size: var(--seccl-font-size-xsmall);
}
:root {
  /* Core button styles */
  --seccl-button-border-radius: var(--seccl-border-radius-medium);
  --seccl-button-padding-vertical: var(--seccl-spacing-xxsmall);
  --seccl-button-padding-horizontal: var(--seccl-spacing-medium);
  --seccl-button-padding: var(--seccl-button-padding-vertical) var(--seccl-button-padding-horizontal);
  --seccl-button-font: var(--seccl-primary-font);
  --seccl-button-font-size: var(--seccl-font-size-small);
  --seccl-button-min-width: 11.5rem;
  --seccl-button-height: var(--seccl-spacing-xxxxlarge);

  /* Hover and disabled styles */
  --seccl-button-disabled-color: var(--seccl-color-white);
  --seccl-button-disabled-bg-color: var(--seccl-color-grey-4);
  --seccl-button-disabled-border: 1px solid var(--seccl-color-grey-3);

  /* Outlined button styles */
  --seccl-button-outlined-color: var(--seccl-button-color);
  --seccl-button-outlined-bg-color: transparent;
  --seccl-button-outlined-hover-color: var(--seccl-button-dark);
  --seccl-button-outlined-hover-border: 1px solid var(--seccl-button-dark);
  --seccl-button-outlined-disabled-color: var(--seccl-color-grey-4);
  --seccl-button-outlined-disabled-border: 1px solid var(--seccl-color-grey-4);
}

.seccl-button {
  color: var(--seccl-button-contrast);
  min-height: var(--seccl-button-height);
  background-color: var(--seccl-button-color);
  border: var(--seccl-button-border);
  min-width: var(--seccl-button-min-width);
  border-radius: var(--seccl-button-border-radius);
  padding: var(--seccl-button-padding);
  font-family: var(--seccl-button-font);
  font-size: var(--seccl-button-font-size);
  text-transform: none;
  box-shadow: 0 0 0 1px transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.seccl-button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  white-space: nowrap;
  color: inherit;
}

.seccl-button:hover {
  background-color: var(--seccl-button-dark);
  border: 1px solid var(--seccl-button-dark);
}

.seccl-button:focus-visible {
  background-color: var(--seccl-button-dark);
  border-color: var(--seccl-button-light);
  box-shadow: 0 0 0 2px var(--seccl-button-light);
}

.seccl-button.Mui-disabled {
  color: var(--seccl-button-disabled-color);
  background-color: var(--seccl-button-disabled-bg-color);
  border: var(--seccl-button-disabled-border);
}

.seccl-button.MuiButton-outlined {
  background-color: var(--seccl-button-outlined-bg-color);
  border: var(--seccl-button-outlined-border);
  color: var(--seccl-button-color);
}

.seccl-button.MuiButton-outlined:hover {
  color: var(--seccl-button-dark);
  background-color: var(--seccl-button-outlined-bg-color);
  border: 1px solid var(--seccl-button-dark);
}

.seccl-button.MuiButton-outlined:focus,
.seccl-button.MuiButton-outlined:focus-visible {
  box-shadow: 0 0 0 1px var(--seccl-button-color);
}

.seccl-button.MuiButton-outlined.Mui-disabled {
  color: var(--seccl-button-outlined-disabled-color);
  background-color: var(--seccl-button-outlined-bg-color);
  border: var(--seccl-button-outlined-disabled-border);
}

.seccl-button.MuiButton-startIcon {
  flex: 0;
}

.seccl-button-spinner {
  --seccl-spinner-color: currentcolor;
}
.seccl-popover-menu {
  --seccl-popover-min-width: 130px;

  pointer-events: none;
}

.seccl-menu-item[data-separator='true'] {
  border-top: 1px solid var(--seccl-color-grey-2);
}

.seccl-popover-menu-list {
  pointer-events: all;
  min-width: var(--seccl-popover-min-width);
}
.seccl-actions {
  display: flex;
  flex-direction: row-reverse;
  gap: var(--seccl-spacing-medium);
}

.seccl-actions-primary-item,
.seccl-actions-secondary-item,
.seccl-actions-tertiary-item {
  --seccl-button-min-width: fit-content;
  --seccl-button-padding: var(--seccl-spacing-xsmall) var(--seccl-spacing-medium);

  text-orientation: sideways-right;
  font-weight: var(--seccl-font-weight-regular);
}

.seccl-actions-secondary-item,
.seccl-actions-tertiary-item {
  --seccl-button-text-color: var(--seccl-color-grey-6);
}

.seccl-table-actions-item {
  background-color: transparent;
}
.listbox-container {
  max-width: 100vw;
  text-align: left;
  padding: 0;
  line-height: var(--seccl-spacing-xxxlarge);
  display: table;
  margin: 0;
}

.listbox-header-row {
  display: table-row;
  color: var(--seccl-color-grey-3);
  font-size: var(--seccl-font-size-xsmall);
  pointer-events: none;
}

.listbox-container .listbox-row {
  display: table-row;
  max-width: 100%;
}

.listbox-container :hover {
  background-color: var(--seccl-color-grey-1);
}

.listbox-container > .listbox-header-row :hover {
  background-color: none;
}

.listbox-cell {
  display: table-cell;
  padding: 0 var(--seccl-spacing-large);
  max-width: 25vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.seccl-icon-button {
  --seccl-icon-button-hover-bg-color: var(--seccl-color-grey-1);
}

.seccl-icon-button.Mui-focusVisible,
.seccl-icon-button:hover {
  background-color: var(--seccl-icon-button-hover-bg-color);
}

.seccl-icon-button.Mui-disabled {
  color: var(--seccl-color-grey-3);
}
/* Helper text */
.seccl-input-form-helper-text {
  color: var(--seccl-color-grey-3);
  font-size: var(--seccl-font-size-xsmall);
  margin-top: var(--seccl-spacing-xxxsmall);
  line-height: unset;
  display: flex;
  align-items: center;
}

.seccl-input-form-helper-text-icon {
  margin-right: var(--seccl-spacing-xxxsmall);
  flex-shrink: 0;
}
.seccl-pill {
  --pill-padding-vertical: 3px;

  position: relative;
  background-color: var(--seccl-pill-bg-color);
  color: var(--seccl-pill-text-color);
  padding: var(--pill-padding-vertical) var(--seccl-spacing-xsmall);
  border-radius: 100px;
  box-sizing: border-box;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.seccl-pill-start-adornment {
  margin-right: var(--seccl-spacing-xsmall);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--seccl-pill-icon-color);
  flex-shrink: 0;
}

.seccl-pill-end-adornment {
  margin-left: var(--seccl-spacing-xxsmall);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--seccl-pill-icon-color);
  flex-shrink: 0;
}
:root {
  --seccl-tooltip-bg-color: rgb(29 23 57 / 80%);
}

.seccl-tooltip-wrapper {
  display: flex;
  align-items: center;
}

.seccl-tooltip {
  max-width: 100%;
  display: flex;
  align-items: center;
}

.seccl-tooltip-popper {
  background-color: var(--seccl-tooltip-bg-color);
  border-radius: var(--seccl-border-radius-small);
  color: var(--seccl-color-white);
  font-size: var(--seccl-font-size-xsmall);
  font-family: var(--seccl-font-family);
  font-weight: var(--seccl-font-weight-regular);
}

.seccl-tooltip-icon {
  cursor: pointer;
  font-size: var(--seccl-font-size-small);
  color: var(--seccl-color-grey-3);
  display: flex;
  align-items: center;
  margin-left: var(--seccl-spacing-xsmall);
}
.seccl-auto-complete-input-wrapper {
  padding: var(--seccl-spacing-xxxsmall) var(--seccl-input-padding-horizontal);
  flex-wrap: wrap;
}

.seccl-auto-complete-input-wrapper.MuiInputBase-adornedEnd {
  /* override padding for search icon, mui hard code it  */
  padding-right: 40px;
}

.seccl-auto-complete-input {
  padding: var(--seccl-spacing-xxxsmall) 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.seccl-auto-complete-input-wrapper.Mui-disabled {
  pointer-events: none;
}

.seccl-auto-complete-input-wrapper .seccl-input-adornment {
  position: absolute;
  right: var(--seccl-spacing-medium);
  top: 50%;
}

.seccl-auto-complete-pill {
  padding-right: var(--pill-padding-vertical);
}

.seccl-auto-complete-pill[data-disabled='true'] {
  pointer-events: none;
  opacity: 0.7;
}

.seccl-auto-complete-pill-close {
  padding: var(--pill-padding-vertical);
  color: var(--seccl-pill-text-color);
  font-size: var(--seccl-font-size-xxsmall);
  transition: color 0.1s, background-color 0.1s;
}

.seccl-auto-complete-pill-close:hover {
  color: var(--seccl-color-white);
  background-color: var(--seccl-pill-text-color);
}

.seccl-auto-complete-group-label {
  font-family: var(--seccl-primary-font);
}
/* Form control */

:root {
  --seccl-input-border-focused-color: var(--seccl-color-grey-4);
  --seccl-input-border-color: var(--seccl-color-grey-2);
  --seccl-input-border-radius: var(--seccl-border-radius-medium);
  --seccl-input-padding-vertical: var(--seccl-spacing-xsmall);
  --seccl-input-padding-horizontal: var(--seccl-spacing-medium);
  --seccl-input-label-font-size: var(--seccl-font-size-small);
  --seccl-input-font-size: var(--seccl-font-size-small);
  --seccl-input-color: var(--seccl-color-grey-5);
  --seccl-input-background-color: var(--seccl-color-white);
  --seccl-input-placeholder-color: var(--seccl-color-grey-2);
  --seccl-input-error-background-color: #fdf7f6;
  --seccl-input-min-width: 250px;
  --seccl-input-min-height: 50px;
  --seccl-label-height: 26.4px;
}

.seccl-input-form-control {
  display: inline-flex;
  flex-direction: column;
  font-family: var(--seccl-primary-font);
  margin: var(--seccl-spacing-small) 0;
  min-width: var(--seccl-input-min-width);
}

.seccl-input-form-control .seccl-tooltip-icon {
  margin-bottom: var(--seccl-spacing-xxsmall);
}

.seccl-input-container-wrapper {
  display: flex;
  width: 100%;
  min-width: var(--seccl-input-min-width);
}

.seccl-input-action {
  padding-left: var(--seccl-input-padding-horizontal);
}

/* Input wrapper */
.seccl-input-wrapper {
  outline: 1px solid transparent;
  border: 1px solid var(--seccl-input-border-color);
  border-radius: var(--seccl-input-border-radius);
  background-color: var(--seccl-input-background-color);
  font-size: var(--seccl-input-font-size);
  box-shadow: 0 0 0 1px transparent;
  transition: border-color 0.1s, box-shadow 0.1s, background-color 0.1s;
  min-height: var(--seccl-input-min-height);
  color: var(--seccl-input-color);
}

.seccl-input-wrapper.MuiInputBase-adornedStart {
  padding-left: var(--seccl-input-padding-horizontal);
}

.seccl-input-wrapper.MuiInputBase-adornedEnd {
  padding-right: var(--seccl-input-padding-horizontal);
}

.seccl-input-wrapper.Mui-focused:not(.MuiInputBase-readOnly) {
  border-color: var(--seccl-input-border-focused-color);
  box-shadow: 0 0 0 1px var(--seccl-input-border-focused-color);
}

.seccl-input-wrapper.Mui-error {
  border-color: var(--seccl-color-negative);
  background-color: var(--seccl-input-error-background-color);
}

.seccl-input-wrapper.Mui-error.Mui-focused {
  border-color: var(--seccl-color-negative);
  box-shadow: 0 0 0 1px var(--seccl-color-negative);
}

/* Input */
.seccl-input {
  padding: var(--seccl-input-padding-vertical) var(--seccl-input-padding-horizontal);
}

.seccl-input.MuiInputBase-adornedStart {
  padding-left: 0;
}

.seccl-input.MuiInputBase-adornedEnd {
  padding-right: 0;
}

.seccl-input::placeholder {
  color: var(--seccl-input-placeholder-color);
}

.seccl-input.Mui-disabled::placeholder {
  -webkit-text-fill-color: var(--seccl-color-grey-2);
}

/* Label */
.seccl-input-label {
  font-size: var(--seccl-input-label-font-size);
  margin-bottom: var(--seccl-spacing-xxsmall);
  line-height: unset;
}

.seccl-input-label.Mui-disabled {
  color: var(--seccl-color-grey-3);
}

/* Adornment */
.seccl-input-adornment {
  margin: 0;
  color: currentcolor;
  pointer-events: none;
}

.seccl-input-adornment-spinner {
  --seccl-spinner-color: currentcolor;
}
.seccl-banner {
  --seccl-banner-max-width: 900px;

  position: relative;
  background-color: var(--seccl-banner-bg-color);
  border: 1px solid var(--seccl-banner-icon-color);
  padding: var(--seccl-spacing-medium);
  border-radius: var(--seccl-border-radius-small);
  box-sizing: border-box;
  overflow: hidden;
  margin: var(--seccl-spacing-medium) 0;
  color: #1d1739cc;
  display: flex;
  flex-direction: column;
  gap: var(--seccl-spacing-medium);
  width: fit-content;
}

.seccl-banner-header {
  display: flex;
  align-items: center;
  color: var(--seccl-color-grey-6);
  gap: var(--seccl-spacing-xsmall);
}

.seccl-banner-header p {
  margin: var(--seccl-spacing-xsmall);
}

.seccl-banner-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--seccl-banner-icon-color);
  flex-shrink: 0;
}

.seccl-banner-container-no-heading {
  display: flex;
}

.seccl-banner-content-no-heading {
  margin-left: var(--seccl-spacing-small);
}
.seccl-element-separated-list {
  display: flex;
  row-gap: var(--seccl-spacing-xsmall);
  flex-flow: row wrap;
  align-items: center;
}

.seccl-element-separated-list-separator {
  padding-left: var(--seccl-spacing-xsmall);
  padding-right: var(--seccl-spacing-xsmall);
}

.seccl-element-separated-list-separator-item {
  font-size: inherit;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
}
:root {
  --seccl-breadcrumbs-active-color: var(--seccl-color-accent);
  --seccl-breadcrumbs-inactive-color: var(--seccl-color-grey-4);
}

.seccl-breadcrumbs {
  color: var(--seccl-breadcrumbs-inactive-color);
  font-size: var(--seccl-font-size-xsmall);
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.seccl-breadcrumbs-link {
  color: var(--seccl-breadcrumbs-active-color);
}

.seccl-breadcrumbs-link:hover {
  text-decoration: underline;
}

.seccl-breadcrumbs-crumb[data-last='false'] {
  color: var(--seccl-breadcrumbs-active-color);
  font-size: var(--seccl-font-size-xsmall);
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}
.seccl-card {
  border: 1px solid var(--seccl-color-grey-2);
  border-radius: var(--seccl-border-radius-medium);
  padding: var(--seccl-spacing-large);
  background: var(--seccl-color-white);
  position: relative;
  overflow: hidden;
}
.seccl-input-action {
  --seccl-input-action-color: var(--seccl-color-accent);
  --seccl-input-action-color-hover: var(--seccl-color-accent-dark);
  --seccl-input-action-margin: var(--seccl-spacing-xsmall);
  --seccl-input-action-add-padding: var(--seccl-spacing-xxxsmall);
  --seccl-input-action-font: var(--seccl-primary-font);
  --seccl-input-action-font-size: var(--seccl-font-size-small);
  --seccl-input-action-color-disabled: var(--seccl-color-grey-3);
  --seccl-input-action-icon-size: var(--seccl-font-size-large);

  display: flex;
  color: var(--seccl-input-action-color);
  font-family: var(--seccl-input-action-font);
  font-size: var(--seccl-input-action-font-size);
  justify-content: flex-start;
  white-space: nowrap;
  padding: var(--seccl-spacing-xxsmall);
  border: 2px solid transparent;
  min-height: var(--seccl-button-height);
  border-radius: var(--seccl-border-radius-medium);
  transition: background-color 0.2s, border-color 0.2s;
}

.seccl-input-action:hover {
  cursor: pointer;
  color: var(--seccl-input-action-color-hover);
}

.seccl-input-action:focus-visible {
  background-color: var(--seccl-color-accent-light);
}

.seccl-input-action.Mui-disabled {
  color: var(--seccl-input-action-color-disabled);
}

.seccl-input-action-icon {
  display: flex;
  font-size: var(--seccl-input-action-icon-size);
  padding-right: var(--seccl-input-action-add-padding);
}
.seccl-exception-card {
  border: none;
  min-width: 330px;
  padding: var(--seccl-spacing-xlarge);
}

.seccl-exception-card[data-severity='high'] {
  border-left: 4px solid var(--seccl-color-negative);
}

.seccl-exception-card[data-severity='medium'] {
  border-left: 4px solid var(--seccl-color-warning);
}

/* Kept the same as medium until a third warning colour is confirmed */
.seccl-exception-card[data-severity='low'] {
  border-left: 4px solid var(--seccl-color-warning);
}

.seccl-exception-card[data-severity='info'] {
  border-left: 4px solid var(--seccl-color-info);
}

.seccl-exception-card-contents {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  white-space: nowrap;
}

.seccl-exception-card-metric-row {
  width: 100%;
  margin-top: var(--seccl-spacing-medium);
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.seccl-exception-card .seccl-input-action {
  padding-bottom: 0;
  min-height: min-content;
}

.seccl-exception-card-metric {
  font-size: var(--seccl-font-size-xxlarge);
  font-weight: var(--seccl-font-weight-bold);
}

.seccl-exception-card-title {
  font-size: var(--seccl-font-size-medium);
  font-weight: var(--seccl-font-weight-bold);
  color: var(--seccl-color-grey-5);
}

.seccl-exception-card-pending {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--seccl-table-pending-row-bg-color);
  color: var(--seccl-color-brand-secondary);
}
.seccl-metric-card-contents {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  white-space: nowrap;
}

.seccl-metric-card-metric {
  font-size: var(--seccl-font-size-xlarge);
  font-weight: var(--seccl-font-weight-bold);
  margin-top: var(--seccl-spacing-xsmall);
}

.seccl-metric-card-title {
  font-size: var(--seccl-font-size-small);
  font-weight: var(--seccl-font-weight-bold);
  color: var(--seccl-color-grey-5);
}

.seccl-metric-card-pending {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--seccl-table-pending-row-bg-color);
}
.seccl-metric-card-container {
  box-sizing: border-box;
  display: flex;
  overflow: auto;
  gap: var(--seccl-spacing-large);
  position: relative;
  padding: var(--seccl-spacing-large);
  max-width: 100vw;
}

.seccl-metric-card-container .seccl-card {
  flex-grow: 1;
  flex-shrink: 0;
  padding-right: var(--seccl-spacing-xxlarge);
}
.seccl-checkbox-form-control {
  margin: var(--seccl-spacing-xxsmall) 0;
}

.seccl-checkbox {
  --seccl-checkbox-checked-color: var(--seccl-color-accent);
  --seccl-checkbox-unchecked-color: var(--seccl-color-grey-3);
  --seccl-checkbox-disabled-color: var(--seccl-color-grey-2);
  --seccl-checkbox-error-color: var(--seccl-color-negative);
  --seccl-checkbox-padding: var(--seccl-spacing-xxxsmall);

  color: var(--seccl-checkbox-unchecked-color);
  padding: var(--seccl-checkbox-padding);
}

.seccl-checkbox.Mui-checked,
.seccl-checkbox.MuiCheckbox-indeterminate {
  color: var(--seccl-checkbox-checked-color);
}

.seccl-checkbox.Mui-disabled {
  color: var(--seccl-checkbox-disabled-color);
}

/* stylelint-disable-next-line selector-not-notation */
.seccl-checkbox-form-control[data-error='true'] .seccl-checkbox:not(.Mui-checked):not(.Mui-disabled) {
  color: var(--seccl-checkbox-error-color);
}

.seccl-checkbox-form-control-label[data-solo='true'] {
  margin-right: unset;
}

.seccl-checkbox-form-control-label[data-align-top='true'] {
  align-items: flex-start;
}

.seccl-checkbox-form-control-label {
  margin-left: 0;
}
.seccl-collapsible {
  border: 1px solid var(--seccl-color-grey-2);
  border-radius: var(--seccl-border-radius-large);
  background-color: var(--seccl-color-white);
  box-shadow: var(--seccl-box-shadow);
  overflow: hidden;
}

.seccl-collapsible-heading-text-detail-container,
.seccl-collapsible-mobile-heading-text-detail-container {
  width: 100%;
  align-items: center;
  gap: var(--seccl-spacing-small);
  border-bottom: none;
  display: flex;
}

.seccl-collapsible-heading {
  padding: var(--seccl-spacing-medium) var(--seccl-spacing-large);
  align-items: center;
  gap: var(--seccl-spacing-small);
  border-bottom: none;
  display: flex;
  cursor: pointer;
}

.seccl-collapsible-heading-text {
  color: var(--seccl-color-grey-6);
  font-size: var(--seccl-font-size-xlarge);
  /* stylelint-disable-next-line property-no-unknown */
  box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
}

.seccl-collapsible-mobile-heading-text-detail-container {
  flex-direction: column;
}

.seccl-collapsible-detail {
  margin-left: auto;
  margin-right: 0;
}

.seccl-collapsible-icon-button {
  border-radius: 50%;
  border: 1px solid var(--seccl-color-grey-2);
  padding: var(--seccl-spacing-xxsmall);
  color: var(--seccl-color-grey-4);
  box-shadow: var(--seccl-box-shadow);
  font-size: var(--seccl-font-size-small);
  flex-shrink: 0;
}

.seccl-collapsible-icon {
  transition: transform 0.3s;
}

.seccl-collapsible-icon[data-expanded='true'] {
  transform: rotate(-180deg);
}

.seccl-collapsible-content {
  color: var(--seccl-color-grey-6);
  border-top: 1px solid var(--seccl-color-grey-2);
}

.seccl-collapsible-content .seccl-collapsible .seccl-collapsible-heading {
  padding-left: var(--seccl-spacing-xxlarge);
  border-top: 1px solid var(--seccl-color-grey-2);
}

.seccl-collapsible-content .seccl-collapsible .seccl-collapsible-heading .seccl-collapsible-heading-text {
  font-size: var(--seccl-font-size-large);
}

.seccl-collapsible-content .seccl-collapsible {
  border-radius: 0;
  box-shadow: none;
  border: none;
}

.seccl-collapsible-content .seccl-collapsible .seccl-collapsible-content {
  border-top: none;
}
.seccl-collapsible-sections-container .seccl-collapsible {
  border-radius: 0;
  box-shadow: none;
  border: none;
}

.seccl-collapsible-sections-container .seccl-collapsible .seccl-collapsible-content {
  border-top: none;
}

.seccl-collapsible-sections-container .seccl-collapsible .seccl-collapsible-heading {
  padding-left: var(--seccl-spacing-xxlarge);
  border-top: 1px solid var(--seccl-color-grey-2);
}

.seccl-collapsible-sections-container .seccl-collapsible .seccl-collapsible-heading .seccl-collapsible-heading-text {
  font-size: var(--seccl-font-size-xlarge);
}
.seccl-currency-input {
  --seccl-currency-input-text-align: end;
}

.seccl-currency-input .seccl-input {
  text-align: var(--seccl-currency-input-text-align);
}
/* Primary Calendar Cell Styles */
.seccl-calendar-cell {
  text-align: center;
  flex-shrink: 0;
  border-radius: 0;
  margin: 2px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* Cell Inner Styles and Modifiers */
.cell-inner {
  display: flex;
  padding: 0;
  font-size: var(--seccl-font-size-xsmall);
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: var(--seccl-calendar-cell-size);
  height: var(--seccl-calendar-cell-size);
  border-radius: 50%;
}

.seccl-calendar-cell:focus-visible {
  outline: none;
}

.seccl-calendar-cell:focus-visible .cell-inner {
  background-color: var(--seccl-color-accent-light);
}

.seccl-calendar-cell:focus-visible.cell-selected .cell-inner {
  background-color: var(--seccl-color-accent-lighter);
}

.cell-inner-selected {
  color: var(--seccl-color-white);
  background-color: var(--seccl-color-accent);
}

.seccl-calendar-cell:focus-visible.cell-selected .cell-inner-selected {
  background-color: var(--seccl-color-accent-dark);
}

.cell-inner-today {
  box-shadow: inset 0 0 0 2px var(--seccl-color-accent);
}

/* Cell Selection and Availability Styles */
.cell-selected {
  background-color: var(--seccl-color-accent-light);
}

.cell-unavailable {
  color: var(--seccl-color-negative);
}

.cell-disabled {
  color: var(--seccl-color-grey-2);
  cursor: default;
}

/* Cell Shape Modifiers */
.cell-rounded-right {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.cell-rounded-left {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
/* Table */
.seccl-range-calendar-grid {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.seccl-range-calendar-grid td {
  padding: 0;
}

.seccl-range-calendar-grid th {
  font-weight: var(--seccl-font-weight-regular);
  font-size: var(--seccl-font-size-xsmall);
  padding-bottom: var(--seccl-spacing-small);
  color: var(--seccl-color-grey-3);
}
/* stylelint-disable scss/operator-no-newline-after */
/* stylelint-disable custom-property-pattern */

/* Select input */
.seccl-select-input-wrapper.MuiInputBase-adornedEnd {
  padding-right: calc(24px + var(--seccl-input-padding-horizontal));
}

.seccl-select-menu-item {
  padding: 0;
  min-height: fit-content;
}

.seccl-select-menu-item-content {
  width: 100%;
  min-height: inherit;
  padding: var(--seccl-spacing-xxsmall) var(--seccl-spacing-small);
  color: var(--seccl-color-grey-6);
}

.seccl-select-menu-item[data-separator='true'] {
  border-top: 1px solid var(--seccl-color-grey-2);
}

/* We have to override some mui styling here with their own variables as they have not implemented the focus state with css vars yet */
.seccl-select-menu-item:focus {
  background-color: var(--mui-palette-action-hover);
}

.seccl-select-menu-group-header {
  font-family: var(--seccl-font-primary);
  padding: var(--seccl-spacing-xxsmall) var(--seccl-spacing-small);
  line-height: unset;
  margin-top: var(--seccl-spacing-xsmall);
}

.seccl-select-menu-item.Mui-selected {
  background-color: rgba(var(--mui-palette-primary-mainChannel) / var(--mui-palette-action-selectedOpacity));
}

.seccl-menu-item.Mui-selected:focus {
  background-color: rgba(
    var(--mui-palette-primary-mainChannel) /
      calc(var(--mui-palette-action-selectedOpacity) + var(--mui-palette-action-hoverOpacity))
  );
}

.seccl-select-input-icon {
  user-select: none;
  width: 0.8em;
  height: 0.8em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: absolute;
  right: var(--seccl-input-padding-horizontal);
  top: calc(50% - 0.4em);
  pointer-events: none;
}
/* Main Header Styles */
.seccl-range-calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--seccl-spacing-medium);
  flex-grow: 1;
  margin-bottom: var(--seccl-spacing-small);
}

/* Heading */
.seccl-range-calendar-heading {
  position: relative;
}

.seccl-range-calendar-heading .seccl-input-form-control {
  min-width: unset;
  margin: 0;
}

/* Month Buttons */
.seccl-range-calendar-month-buttons {
  display: flex;
  align-items: center;
  gap: var(--seccl-spacing-small);
}

/* Year Select */
.seccl-range-calendar-year-select {
  --seccl-input-border-color: transparent;
  --seccl-input-background-color: transparent;
}

.seccl-range-calendar-year-select.seccl-input-wrapper .seccl-input {
  padding: 0 var(--seccl-spacing-xxxlarge) 0 var(--seccl-spacing-xxxsmall);
}
.seccl-range-calendar-wrapper {
  display: flex;
  gap: var(--seccl-spacing-medium);

  --seccl-calendar-cell-size: 40px;
  --seccl-calendar-padding: var(--seccl-spacing-small);
}

.seccl-range-calendar-container {
  background-color: var(--seccl-color-white);
  padding: var(--seccl-calendar-padding);
  padding-top: var(--seccl-spacing-xxsmall);
  border-radius: var(--seccl-border-radius-medium);
  border: 1px solid var(--seccl-color-grey-2);
}

.seccl-range-calendar {
  display: inline-flex;
  flex-direction: column;
  width: calc((7 * var(--seccl-calendar-cell-size)) + (2 * var(--seccl-calendar-padding)));
}
.seccl-date-input-container {
  width: fit-content;
}

.seccl-date-input-adornment-calendar {
  pointer-events: visible;
}
.seccl-range-calendar-inputs {
  display: inline-flex;
  gap: var(--seccl-spacing-medium);
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.seccl-range-calendar-inputs .seccl-input-form-control {
  min-width: unset;
}
.seccl-range-calendar-presets {
  border-right: 1px solid var(--seccl-color-grey-2);
}
.seccl-dialog-title-row {
  padding: var(--seccl-spacing-large) var(--seccl-spacing-large) var(--seccl-spacing-small) var(--seccl-spacing-large);
}

.seccl-dialog-title {
  margin: 0;
}

.seccl-close-dialog-icon {
  position: absolute;
  top: var(--seccl-spacing-xxxsmall);
  right: 0;
}
:root {
  --seccl-dialog-width: 500px;
}

.seccl-dialog {
  border-radius: var(--seccl-border-radius-medium);
  max-width: var(--seccl-dialog-width);
}

.seccl-dialog-body {
  padding: 0 var(--seccl-spacing-large);
  flex-grow: 1;
}

.seccl-dialog-action-row {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding: var(--seccl-spacing-large);
  gap: var(--seccl-spacing-medium);
}

.seccl-dialog-action-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--seccl-spacing-medium);
  padding: var(--seccl-spacing-large);
}
.seccl-display-input-value {
  padding: var(--seccl-spacing-small) 0;
}
.seccl-stateful-button {
  cursor: pointer;
  padding: var(--seccl-spacing-xxsmall) var(--seccl-spacing-xsmall);
  margin: var(--seccl-spacing-small) 1px;
  background-color: var(--seccl-stateful-button-color);
  border: var(--seccl-stateful-button-border);
  border-radius: var(--seccl-border-radius-medium);
  font-size: var(--seccl-input-font-size);
  display: inline-flex;
  gap: var(--seccl-spacing-xxsmall);
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  color: var(--seccl-stateful-button-text-color);
}

.seccl-stateful-button:hover {
  background-color: var(--seccl-stateful-button-hover-color);
  border: 1px solid var(--seccl-stateful-button-hover-border);
}

.seccl-stateful-button:focus-visible {
  border: 1px solid var(--seccl-stateful-button-hover-border);
  box-shadow: 0 0 0 1px var(--seccl-stateful-button-hover-border);
  outline: unset;
}

.seccl-stateful-button-start-adornment,
.seccl-stateful-button-end-adornment {
  color: var(--seccl-stateful-button-icon-color);
}

.seccl-stateful-button.seccl-stateful-list-button {
  padding-left: unset;
  border: none;
}

.seccl-stateful-button.seccl-stateful-list-button:hover {
  background-color: var(--seccl-stateful-button-hover-color);
  border: unset;
}

.seccl-stateful-button.seccl-stateful-list-button:focus-visible {
  background-color: var(--seccl-stateful-button-hover-color);
  border: unset;
  box-shadow: unset;
}

.seccl-stateful-button-content {
  white-space: nowrap;
}
.seccl-stateful-icon-button {
  border: 1px solid var(--seccl-input-border-color);
  border-radius: var(--seccl-input-border-radius);
  color: var(--seccl-stateful-icon-button-color);
  background-color: var(--seccl-stateful-icon-button-background-color);
  border-color: var(--seccl-stateful-icon-button-border-color);
  margin: 1px;
}

.seccl-stateful-icon-button:hover {
  background-color: var(--seccl-stateful-icon-button-background-color);
}

.seccl-stateful-icon-button:focus-visible {
  box-shadow: 0 0 0 1px var(--seccl-stateful-icon-button-color);
}
.seccl-drawer {
  border-radius: var(--seccl-border-radius-large) var(--seccl-border-radius-large) 0 0;
  background-color: var(--seccl-color-white);
}

.seccl-drawer-header {
  padding: var(--seccl-spacing-small) var(--seccl-spacing-medium) 0 var(--seccl-spacing-medium);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: var(--seccl-spacing-medium);
}

.seccl-drawer-divider {
  color: var(--seccl-color-grey-2);
}

.seccl-drawer-content {
  display: flex;
  flex-direction: column;
  padding: var(--seccl-spacing-medium);
}

.seccl-drawer-actions {
  display: flex;
  flex-direction: column;
  padding: var(--seccl-spacing-medium);
  gap: var(--seccl-spacing-medium);
}

.seccl-drawer-secondary-actions {
  display: flex;
  flex-direction: row;
  padding: 0 var(--seccl-spacing-small) var(--seccl-spacing-small) var(--seccl-spacing-small);
  gap: var(--seccl-spacing-medium);
  overflow-x: auto;
}
.seccl-spacer {
  flex-grow: 1;
}
.seccl-popover {
  pointer-events: none;
}

.seccl-popover-paper {
  pointer-events: all;
}
.seccl-filter-actions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  gap: var(--seccl-spacing-medium);
  padding: var(--seccl-spacing-small);
  border-top: 1px solid var(--seccl-color-grey-2);
}

.seccl-filter-action {
  --seccl-filter-action-max-width: 130px;

  max-width: var(--seccl-filter-action-max-width);
  min-width: unset;
  font-size: var(--seccl-font-size-xsmall);
}
.seccl-filter-container {
  padding: var(--seccl-spacing-small);
}
.seccl-filters-container {
  display: flex;
  align-items: center;
  gap: var(--seccl-spacing-xsmall);
  overflow-x: hidden;
}

.seccl-filters-list {
  display: flex;
  align-items: center;
  gap: var(--seccl-spacing-xsmall);
  overflow-x: auto;
}

.seccl-drawer-filters-list {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.seccl-filter-by {
  white-space: nowrap;
}

.seccl-drawer-filter {
  display: flex;
  flex-direction: column;
  gap: var(--seccl-spacing-small);
}
.seccl-flyout {
  display: flex;
  flex-direction: column;
}

.seccl-flyout-fullscreen {
  height: 100vh;
}

.seccl-flyout-side {
  width: 80vw;
}

.seccl-flyout-content {
  flex-grow: 1;
  padding: var(--seccl-spacing-small);
  background: var(--seccl-app-background);
}

.seccl-flyout-side .seccl-flyout-header {
  height: var(--seccl-header-toolbar-height);
}
.seccl-form-array {
  display: inline-grid;
  margin: var(--seccl-spacing-small) 0;
  gap: var(--seccl-spacing-small);
}

.seccl-form-array .seccl-form-array-action,
.seccl-form-array-input-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: var(--seccl-input-min-height);
}

.seccl-form-array .seccl-form-array-input .seccl-input-form-control {
  width: 100%;
  min-width: unset;
  margin: 0;
}

.seccl-form-array .seccl-form-array-input {
  min-height: var(--seccl-input-min-height);
}

.seccl-form-array-label {
  font-size: var(--seccl-input-label-font-size);
  margin-bottom: var(--seccl-spacing-xxsmall);
  line-height: unset;
}

.seccl-form-array .seccl-form-array-label {
  margin-bottom: 0;
}

.seccl-form-array .item-name {
  display: flex;
  height: var(--seccl-input-min-height);
  flex-direction: column;
  justify-content: center;
  padding-right: var(--seccl-spacing-large);
}

.seccl-form-array-input .seccl-tooltip-wrapper {
  display: flex;
  align-items: flex-start;
}

.seccl-form-array-add-row-action-button {
  align-self: flex-start;
  padding-bottom: var(--seccl-spacing-large);
}

.seccl-form-array-mobile {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  gap: var(--seccl-spacing-large);
}

.seccl-form-array-mobile .seccl-form-array-input {
  flex-direction: column;
  display: flex;
}

.seccl-form-array-mobile .seccl-form-array-input .seccl-input-form-control {
  min-width: unset;
  margin: 0;
}

.seccl-form-array-mobile-item-name {
  padding: var(--seccl-spacing-small), var(--seccl-spacing-small);
}

.seccl-form-array-mobile-card {
  background-color: var(--seccl-color-white);
  border-radius: var(--seccl-border-radius-medium);
  padding: var(--seccl-spacing-medium);
  margin-bottom: var(--seccl-spacing-large);
  display: inline-flex;
  flex-direction: column;
  border: 1px solid var(--seccl-color-grey-2);
}

.seccl-footer-item-mobile {
  height: max-content;
}

.seccl-form-array-mobile-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  gap: var(--seccl-spacing-medium);
}

.seccl-form-array-mobile-header {
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--seccl-color-grey-4);
  margin-bottom: var(--seccl-spacing-medium);
}

.seccl-form-array-item-hidden {
  display: none;
}

.seccl-form-array .seccl-form-array-item-name-top {
  grid-column: 1 / span var(--column-count);
  height: unset;
  min-height: unset;
  font-weight: var(--seccl-font-weight-medium);
  margin-bottom: var(--seccl-spacing-xxsmall);
}

.seccl-form-array-item-name-top:not(:first-child) {
  margin-top: var(--seccl-spacing-medium);
}
.seccl-base-form-section-container {
  border: unset;
  padding: 0;
  margin-bottom: var(--seccl-spacing-medium);
  scroll-margin-top: calc(var(--seccl-header-toolbar-height) + var(--seccl-spacing-large));
  max-width: var(--default-form-max-width);
}

.seccl-form-section {
  display: flex;
  flex-direction: column;
}

.seccl-form-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  padding: var(--seccl-spacing-medium) 0;
  border-bottom: var(--seccl-form-border);
  margin-bottom: var(--seccl-form-padding);
  gap: var(--seccl-spacing-xxlarge);
}

.seccl-form-section-heading {
  font-size: var(--seccl-font-size-xlarge);
  margin: 0;
  font-weight: var(--seccl-font-weight-regular);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--seccl-spacing-small);
}

.seccl-form-section-status {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.seccl-form-section-status svg {
  margin-left: var(--seccl-spacing-large);
}

.seccl-form-section-helper-text {
  color: var(--seccl-color-grey-4);
  font-size: var(--seccl-font-size-small);
}
:root {
  /* Core button styles */
  --seccl-form-step-padding: var(--seccl-spacing-small) var(--seccl-spacing-large);
  --seccl-form-step-font-size: var(--seccl-font-size-small);

  /* Hover and disabled styles */
  --seccl-form-step-hover-bg-color: var(--seccl-color-grey-1);
  --seccl-form-step-selected-border-color: var(--seccl-color-info);
}

.seccl-form-step {
  width: 100%;
  color: inherit;
  background-color: transparent;
  padding: var(--seccl-form-step-padding);
  font-size: var(--seccl-form-step-font-size);
  text-transform: none;
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  border-left: 3px solid transparent;
  flex-shrink: 0;
  white-space: nowrap;
  font-weight: var(--seccl-font-weight-medium);
}

.seccl-form-step:hover {
  background-color: var(--seccl-form-step-hover-bg-color);
}

.seccl-form-step[data-selected='true'] {
  border-color: var(--seccl-form-step-selected-border-color);
}

.form-step-icon {
  color: var(--seccl-color-grey-2);
}

.form-step-icon.complete {
  color: var(--seccl-color-positive);
}

.form-step-icon.inprogress {
  color: var(--seccl-color-info);
}

.form-step-icon.alert {
  color: var(--seccl-color-negative);
}

.form-step-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin-left: var(--seccl-spacing-large);
}
.seccl-form-step-list-collapsible-icon {
  transition: transform 0.3s;
}

.seccl-form-step-list-collapsible-icon[data-expanded='true'] {
  transform: rotate(-180deg);
}

.seccl-form-step-list-collapsible-content {
  margin-top: var(--seccl-spacing-small);
  color: var(--seccl-color-grey-6);
}

.seccl-form-step-list-content-indent {
  margin-left: var(--seccl-spacing-large);
}
:root {
  --seccl-form-action-bg-color: var(--seccl-color-white);
}

.seccl-form-actions {
  display: flex;
  justify-content: flex-end;
  padding: var(--seccl-form-padding);
  background-color: var(--seccl-form-action-bg-color);
  border-top: var(--seccl-form-border);
  gap: var(--seccl-form-padding);
}

.seccl-form-actions[data-sticky='true'] {
  position: sticky;
  bottom: 0;
}
.seccl-form-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100vw;
}
.seccl-form-layout {
  display: flex;
  flex-grow: 1;
}

.seccl-form-layout.condense {
  flex-direction: column;

  --seccl-form-padding: var(--seccl-spacing-small);
}

.seccl-form-layout.condense .seccl-form-section-header {
  border-bottom: unset;
}

.seccl-form-layout.condense .seccl-base-form-section-container {
  padding-top: var(--seccl-spacing-large);
  padding-bottom: var(--seccl-spacing-xxxlarge);
  margin-bottom: 0;
  scroll-margin-top: calc(var(--seccl-header-toolbar-height) + var(--seccl-condensed-form-step-list-height));
}
.seccl-form-section-container {
  flex-grow: 1;
  padding: var(--seccl-form-padding);
  overflow: hidden;
}
.seccl-form-step-list-container {
  border-right: var(--seccl-form-border);
  max-width: var(--seccl-form-step-list-max-width);
  width: 100%;
  flex-shrink: 0;
}

.seccl-form-step-list {
  position: sticky;
  top: var(--seccl-header-toolbar-height);
  padding: var(--seccl-form-padding);
  font-weight: var(--seccl-font-weight-regular);
}

.seccl-form-step-count-container {
  display: flex;
  align-items: center;
  gap: var(--seccl-spacing-large);
}

.seccl-form-step-count-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--seccl-spacing-medium);
  padding: var(--seccl-form-padding);
  box-sizing: border-box;
  height: var(--seccl-condensed-form-step-list-height);
}

.seccl-form-step-count {
  display: flex;
  align-items: center;
  gap: var(--seccl-spacing-small);
}

.seccl-form-step-list-condense-container {
  --seccl-condensed-form-step-list-height: 60px;

  position: sticky;
  top: var(--seccl-header-toolbar-height);
  background-color: var(--seccl-color-white);
  z-index: 1;
  border-bottom: var(--seccl-form-border);
}
.seccl-review-items {
  display: flex;
  flex-direction: column;
}

.seccl-review-label {
  font-weight: var(--seccl-font-weight-regular);
}

.seccl-review-name {
  border-bottom: var(--seccl-form-border);
  grid-column: 1 / span 2;
  font-weight: var(--seccl-font-weight-medium);
  padding: var(--seccl-spacing-medium) 0;
  padding-top: var(--seccl-spacing-xlarge);
}

.seccl-review-item {
  display: grid;
  word-wrap: break-word;
  grid-template-columns: 1fr 1fr;
  gap: var(--seccl-spacing-small) var(--seccl-spacing-xxxlarge);
  padding: var(--seccl-spacing-large) 0;
  border-bottom: var(--seccl-form-border);
}

.seccl-review-item-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.seccl-review-section {
  display: flex;
  flex-direction: column;
  max-width: var(--seccl-review-section-max-width);
  margin-bottom: var(--seccl-spacing-medium);
  scroll-margin-top: var(--seccl-header-toolbar-height);
}

.seccl-review-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding: var(--seccl-spacing-medium) 0;
  border-bottom: var(--seccl-form-border);
}

.seccl-review-section-heading {
  font-size: var(--seccl-font-size-xlarge);
  margin: 0;
  font-weight: var(--seccl-font-weight-regular);
}
.seccl-logo-container {
  display: block;
  max-width: 250px;
  height: 100%;
}

.seccl-logo {
  max-width: 100%;
  height: 100%;
  vertical-align: middle;
}
:root {
  /* Header */
  --seccl-header-toolbar-height: 83px;
  --seccl-header-bg-color: var(--seccl-color-brand-primary);
  --seccl-header-color: var(--seccl-color-brand-primary-contrast);
  --seccl-header-padding: var(--seccl-spacing-xlarge) calc(var(--seccl-spacing-large) + var(--seccl-spacing-xxxsmall));

  /* Drawer */
  --seccl-drawer-max-width: 450px;
  --seccl-drawer-padding: var(--seccl-spacing-large);

  /* Button */
  --seccl-navigation-button-hover-bg-color: var(--seccl-color-brand-primary-contrast-hover);
}

/* Buttons */
.seccl-navigation-button {
  text-transform: none;
  font-weight: unset;
  box-shadow: none;
  font-size: var(--seccl-font-size-small);
  cursor: pointer;
  border-radius: var(--seccl-border-radius-small);
  color: var(--seccl-header-color);
  padding: var(--seccl-spacing-xxxsmall) var(--seccl-spacing-medium);
}

.seccl-navigation-button.mobile {
  text-align: start;
  display: block;
  font-size: var(--seccl-font-size-large);
  font-weight: var(--seccl-font-weight-medium);
  margin: var(--seccl-spacing-xxxsmall) 0;
  flex-grow: 1;
}

.seccl-navigation-button.mobile.sub-navigation {
  font-weight: var(--seccl-font-weight-regular);
  font-size: var(--seccl-font-size-small);
  margin-left: var(--seccl-spacing-medium);
}

.seccl-navigation-button.Mui-focusVisible,
.seccl-navigation-button:hover,
.seccl-navigation-icon-button.Mui-focusVisible,
.seccl-navigation-icon-button:hover {
  background-color: var(--seccl-navigation-button-hover-bg-color);
}

/* Containers */
.seccl-navigation-container {
  display: flex;
  flex-grow: 1;
  margin: 0 var(--seccl-spacing-large);
  overflow: hidden;
  color: var(--seccl-header-color);
}

.seccl-settings-container {
  display: flex;
  align-items: center;
  gap: var(--seccl-spacing-large);
}

.seccl-header-toolbar {
  justify-content: space-between;
  height: var(--seccl-header-toolbar-height);
  padding: var(--seccl-header-padding);
  box-sizing: border-box;
  border-bottom: 3px solid var(--seccl-color-brand-secondary);
  color: var(--seccl-header-color);
}

.seccl-mobile-nav {
  display: flex;
  flex-direction: column;
  color: var(--seccl-header-color);
}

.seccl-mobile-nav-search {
  padding: 0 var(--seccl-spacing-medium);
  margin: var(--seccl-spacing-small) 0;
}

.seccl-mobile-nav-search .seccl-input-form-control {
  width: 100%;
}

/* Drawer */
.seccl-draw-menu {
  margin-top: var(--seccl-header-toolbar-height);
  padding: var(--seccl-drawer-padding);
  background-color: var(--seccl-header-bg-color);
  color: var(--seccl-header-color);
  width: 100%;
  max-width: var(--seccl-drawer-max-width);
  box-sizing: border-box;
  height: calc(100% - var(--seccl-header-toolbar-height));
}
:root {
  --seccl-icon-badge-number-size: 24px;
}

.seccl-icon-badge {
  background-color: var(--seccl-icon-badge-bg-color);
  border: 1px solid var(--seccl-icon-badge-icon-color);
  padding: var(--seccl-spacing-xxxsmall);
  border-radius: var(--seccl-border-radius-small);
  display: flex;
  gap: var(--seccl-spacing-medium);
  width: fit-content;
  height: fit-content;
}

.seccl-icon-badge-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--seccl-spacing-small);
}

.seccl-icon-badge-number,
.seccl-icon-badge-number-large {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--seccl-spacing-xsmall);
  border-radius: 50%;
  width: var(--seccl-icon-badge-number-size);
  height: var(--seccl-icon-badge-number-size);
  background-color: var(--seccl-icon-badge-icon-color);
  color: var(--seccl-color-white);
}

.seccl-icon-badge-number {
  font-size: var(--seccl-font-size-xsmall);
}

.seccl-icon-badge-number-large {
  font-size: var(--seccl-font-size-xxsmall);
}

.seccl-icon-badge-icon {
  font-size: var(--seccl-font-size-large);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--seccl-icon-badge-icon-color);
  flex-shrink: 0;
}
:root {
  --seccl-page-section-bg-color: var(--seccl-color-white);
}

.seccl-page-section-wrapper {
  box-sizing: border-box;
  max-width: 100vw;
}

.seccl-page-section {
  overflow: hidden;
}

.seccl-page-section-bg-fill {
  background-color: var(--seccl-page-section-bg-color);
}

.seccl-page-section-header {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  border-bottom: 1px solid var(--seccl-color-grey-2);
  padding: var(--seccl-spacing-large) var(--seccl-spacing-xlarge);
  gap: var(--seccl-spacing-large);
}

.seccl-page-section-heading {
  margin: 0;
  grid-column: 1;
  font-weight: var(--seccl-font-weight-regular);
}

.seccl-page-section-content {
  padding: var(--seccl-spacing-large);
}

.seccl-page-section-actions {
  grid-column: 2;
}

.seccl-page-section-header-items {
  margin-top: var(--seccl-spacing-medium);
  font-size: var(--seccl-font-size-xsmall);
}
.seccl-lozenge {
  --lozenge-padding-vertical: 3px;

  position: relative;
  background-color: var(--seccl-lozenge-bg-color);
  color: var(--seccl-lozenge-text-color);
  padding: var(--lozenge-padding-vertical) var(--seccl-spacing-xsmall);
  border-radius: 100px;
  box-sizing: border-box;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.seccl-lozenge-start-adornment {
  margin-right: var(--seccl-spacing-xsmall);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--seccl-lozenge-icon-color);
  flex-shrink: 0;
}

.seccl-lozenge-end-adornment {
  margin-left: var(--seccl-spacing-xxsmall);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--seccl-lozenge-icon-color);
  flex-shrink: 0;
}
:root {
  --seccl-page-header-title-margin-top: var(--seccl-spacing-medium);
  --seccl-page-header-title-margin-bottom: var(--seccl-spacing-small);
  --seccl-page-header-bg-color: var(--seccl-color-white);
}

.seccl-page-header-wrap {
  display: flex;
  flex-direction: column;
  padding: var(--seccl-spacing-large);
  border-bottom: 1px solid var(--seccl-color-grey-2);
  background-color: var(--seccl-app-background);
}

.seccl-page-header-bg-fill {
  background-color: var(--seccl-page-header-bg-color);
}

.seccl-page-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: var(--seccl-spacing-xxxxlarge);
}

.seccl-page-header-title {
  color: var(--seccl-color-grey-6);
  margin-top: var(--seccl-page-header-title-margin-top);
  margin-bottom: var(--seccl-page-header-title-margin-bottom);
  font-size: 28px;
  font-weight: var(--seccl-font-weight-regular);
  /* stylelint-disable-next-line property-no-unknown */
  box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.seccl-page-header-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.seccl-page-header-breadcrumb {
  border: 1px solid transparent;
}

.seccl-page-header-items {
  color: var(--seccl-color-grey-4);
  font-size: var(--seccl-font-size-xsmall);
}
.seccl-toast-top-line {
  display: flex;
  align-items: center;
  gap: var(--seccl-spacing-xxsmall);
}

.seccl-toast {
  background-color: var(--seccl-toast-light);
  border-bottom: 3px solid var(--seccl-toast-color);
  border-radius: var(--seccl-border-radius-large);
  display: flex;
  padding: var(--seccl-spacing-large);
  flex-direction: column;
  gap: var(--seccl-spacing-xxsmall);
  box-shadow: var(--seccl-box-shadow);
}

.seccl-toast-title {
  color: var(--seccl-color-grey-6);
  font-weight: var(--seccl-font-weight-medium);
  font-size: var(--seccl-font-size-small);
  flex-grow: 1;
}

.seccl-toast-icon {
  color: var(--seccl-toast-color);
}

.seccl-toast-icon svg {
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
}

.seccl-toast-close {
  color: var(--seccl-toast-color);
  cursor: pointer;
}

.seccl-toast-message {
  color: rgb(29 23 57 / 80%);
  font-size: var(--seccl-font-size-xsmall);
}
.seccl-radio-input {
  --seccl-radio-input-checked-color: var(--seccl-color-accent);
  --seccl-radio-input-unchecked-color: var(--seccl-color-grey-3);
  --seccl-radio-input-disabled-color: var(--seccl-color-grey-2);
  --seccl-radio-input-error-color: var(--seccl-color-negative);
  --seccl-radio-input-padding: var(--seccl-spacing-xxxsmall);

  color: var(--seccl-radio-input-unchecked-color);
  padding: var(--seccl-radio-input-padding);
}

.seccl-radio-input.Mui-checked {
  color: var(--seccl-radio-input-checked-color);
}

.seccl-radio-input.Mui-disabled {
  color: var(--seccl-radio-input-disabled-color);
}

.seccl-radio-input-label[data-solo='true'] {
  margin-right: unset;
}

.seccl-radio-input-label {
  margin-left: 0;
}
.seccl-radio-group {
  --seccl-radio-group-padding: var(--seccl-spacing-xxxsmall);

  padding: var(--seccl-radio-group-padding);
}

.seccl-radio-group-form-control {
  margin: var(--seccl-spacing-small) 0;
  margin-left: 0;
}
:root {
  --seccl-slider-track-color: var(--seccl-color-grey-2);
  --seccl-slider-track-border: none;
  --seccl-slider-thumb-color: var(--seccl-color-white);
  --seccl-slider-thumb-border: 1px solid var(--seccl-color-grey-4);
  --seccl-slider-width: 326px;
  --seccl-slider-height: 4px;
  --seccl-slider-padding: 0px;
  --seccl-slider-thumb-size: 20px;
  --seccl-slider-disabled-opacity: 0.7;
}

.seccl-slider-label {
  margin-bottom: var(--seccl-spacing-medium);
  gap: var(--seccl-spacing-small);
  display: block;
  margin-top: var(--seccl-spacing-medium);
}

.seccl-slider {
  width: var(--seccl-slider-width);
  height: var(--seccl-slider-height);
  padding: var(--seccl-slider-padding);
  margin-left: var(--seccl-spacing-xxxsmall);
  margin-bottom: var(--seccl-spacing-xxxlarge);
}

.seccl-slider-mark {
  display: none;
}

.seccl-slider-base {
  padding: var(--seccl-slider-padding);
}

.seccl-slider-disabled {
  opacity: var(--seccl-slider-disabled-opacity);
}

.seccl-slider-track {
  box-sizing: border-box;
  background-color: transparent;
  border: var(--seccl-slider-track-border);
  border-radius: 10px;
}

.seccl-slider-rail {
  background-color: var(--seccl-slider-track-color);
  opacity: 0.6;
}

.seccl-slider-risk-rail {
  background: linear-gradient(
    0.25turn,
    var(--seccl-color-positive) 0%,
    var(--seccl-color-yellow) 50%,
    var(--seccl-color-negative) 100%
  );
  opacity: 0.6;
}

.seccl-slider-thumb {
  box-sizing: border-box;
  background-color: var(--seccl-slider-thumb-color);
  border: var(--seccl-slider-thumb-border);
  width: var(--seccl-slider-thumb-size);
  height: var(--seccl-slider-thumb-size);
}

.seccl-slider-mark-label {
  top: var(--seccl-spacing-medium);
}
.seccl-stat-grid {
  font-size: var(--seccl-font-size-medium);
  min-width: 280px;
}

.seccl-stat-grid th {
  text-align: left;
}

.seccl-stat-grid td {
  text-align: right;
}
.seccl-stat-row {
  padding: var(--seccl-spacing-large) var(--seccl-spacing-medium) var(--seccl-spacing-large)
    var(--seccl-spacing-xxlarge);
  width: 100%;
  background-color: var(--seccl-color-white);
}

.seccl-stat-row th {
  font-size: var(--seccl-font-size-large);
  text-align: left;
}

.seccl-stat-row tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.seccl-stat-row td {
  min-width: max-content;
}

.seccl-stat-row:not(:first-child) {
  border-top: 1px solid var(--seccl-color-grey-2);
}
:root {
  --seccl-switch-track-color-unchecked: var(--seccl-color-grey-2);
  --seccl-switch-track-color-checked: var(--seccl-color-accent);
  --seccl-switch-track-border: none;
  --seccl-switch-thumb-color: var(--seccl-color-white);
  --seccl-switch-thumb-border: 1px solid var(--seccl-color-grey-4);
  --seccl-switch-width: 38px;
  --seccl-switch-height: 24px;
  --seccl-switch-padding: 0px;
  --seccl-switch-thumb-size: calc(var(--seccl-switch-height) - calc(var(--seccl-switch-padding) * 2));
  --seccl-switch-disabled-opacity: 0.7;
}

.seccl-switch-label.label-first {
  flex-direction: row-reverse;
}

.seccl-switch-label {
  margin: 0;
  gap: var(--seccl-spacing-small);
}

.seccl-switch {
  width: var(--seccl-switch-width);
  height: var(--seccl-switch-height);
  padding: var(--seccl-switch-padding);
}

.seccl-switch-base {
  padding: var(--seccl-switch-padding);
}

.seccl-switch-disabled {
  opacity: var(--seccl-switch-disabled-opacity);
}

.seccl-switch-track {
  box-sizing: border-box;
  background-color: var(--seccl-switch-track-color-unchecked);
  border: var(--seccl-switch-track-border);
  border-radius: 500px;
}

.seccl-switch .seccl-switch-checked + .seccl-switch-track {
  box-sizing: border-box;
  background-color: var(--seccl-switch-track-color-checked);
  border: var(--seccl-switch-track-border);
  opacity: 1;
}

.seccl-switch .seccl-switch-checked.seccl-switch-base {
  --transform-distance: calc(
    calc(var(--seccl-switch-width) - calc(var(--seccl-switch-padding) * 2)) - var(--seccl-switch-thumb-size)
  );

  transform: translateX(var(--transform-distance));
}

.seccl-switch-thumb {
  box-sizing: border-box;
  background-color: var(--seccl-switch-thumb-color);
  border: var(--seccl-switch-thumb-border);
  width: var(--seccl-switch-thumb-size);
  height: var(--seccl-switch-thumb-size);
  box-shadow: none;
}
:root {
  --seccl-table-pending-row-bg-color: rgb(255 255 255 / 70%);
}

.seccl-table-pending-row {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--seccl-table-pending-row-bg-color);
  z-index: 1;
}
.seccl-table-error-block-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.seccl-table-error-block-message {
  font-size: var(--seccl-font-size-small);
  max-width: var(--default-form-max-width);
  text-align: center;
  white-space: break-spaces;
}

.seccl-table-error-block-action {
  margin-top: var(--seccl-spacing-small);
}
.seccl-visibility-menu {
  padding: var(--seccl-spacing-medium) 0;
}

.seccl-visibility-menu-item {
  padding: var(--seccl-spacing-xxxsmall) var(--seccl-spacing-small);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  border-top: 3px solid transparent;
  transition: border-color 0.2s;
}

.seccl-visibility-menu-item[data-above='true'][data-is-over='true'] {
  border-top-color: var(--seccl-color-brand-secondary);
}

.seccl-visibility-menu-item[data-above='false'][data-is-over='true'] {
  border-bottom-color: var(--seccl-color-brand-secondary);
}

.seccl-visibility-menu-item:hover {
  background-color: var(--seccl-color-grey-1);
}

.seccl-visibility-menu-item > div {
  flex-grow: 1;
  margin: 0;
}

.seccl-visibility-menu-item[data-is-dragging='true'] {
  cursor: grabbing;
  opacity: 0.8;
}
:root {
  --seccl-table-cell-padding: var(--seccl-spacing-xsmall) var(--seccl-spacing-small);
  --seccl-table-border: 1px solid var(--seccl-color-grey-2);
  --seccl-table-font-size: var(--seccl-font-size-xsmall);
  --seccl-table-row-odd-bg-color: #fafafb;
  --seccl-table-row-even-bg-color: white;
  --seccl-table-row-selected-color: #ecf5fc;
  --seccl-table-header-bg-color: #ebebeb;
  --seccl-table-header-color: #1d1739;
  --seccl-table-footer-bg-color: #ebebeb;
  --seccl-table-footer-color: #1d1739;
}

/* Wrapper */
.seccl-table-wrapper {
  position: relative;
  border: var(--seccl-table-border);
  border-radius: var(--seccl-border-radius-medium);
  overflow: hidden;
}

/* Table container */
.seccl-table-container td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: var(--seccl-table-cell-padding);
  font-size: var(--seccl-table-font-size);
}

.seccl-table-container tbody {
  position: relative;
}

.seccl-table-container [data-can-click='true'] {
  cursor: pointer;
}

.seccl-table-container th {
  white-space: nowrap;
  font-size: var(--seccl-table-font-size);
}

.seccl-table-container thead {
  background-color: var(--seccl-table-header-bg-color);
}

/* Table row */
.seccl-table-container .seccl-table-row:focus-visible {
  background-color: var(--seccl-table-row-selected-color);
}

.seccl-table-row[data-can-click='true']:hover {
  background-color: var(--seccl-table-row-selected-color);
}

:nth-child(even of .seccl-table-row) {
  background-color: var(--seccl-table-row-even-bg-color);
}

:nth-child(odd of .seccl-table-row) {
  background-color: var(--seccl-table-row-odd-bg-color);
}

.seccl-table-row[data-selected='true'] {
  background-color: var(--seccl-table-row-selected-color);
}

.seccl-table-row-expanded .seccl-table-row-expanded-cell {
  white-space: unset;
  padding: 0;
  font-size: var(--seccl-table-font-size);
}

.seccl-table-cell-wrapper {
  display: inline-block;
  line-height: 40px;
  vertical-align: middle;
}

.seccl-table-cell-wrapper * {
  /* Restore the line height to the default MUI value for table cells content */
  line-height: 1.43;
}

.seccl-table-cell-wrapper button {
  padding: 0;
  border: 0;
  background: transparent;
  color: var(--seccl-color-accent);
  cursor: pointer;
}

.seccl-table-cell-wrapper[data-has-ellipsis-overflow='true'] .seccl-tooltip span,
.seccl-table-cell-wrapper[data-has-ellipsis-overflow='true'] .seccl-tooltip b {
  max-width: 200px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.seccl-table-cell-wrapper[data-align='right'] {
  justify-content: flex-end;
}

.seccl-table-empty-state-row {
  height: 300px;
}

.seccl-table-empty-state-cell {
  text-align: center;
  background-color: var(--seccl-table-row-even-bg-color);
  position: relative;
}

/* Expanded content */
.seccl-table-row-expanded-content {
  background-color: var(--seccl-table-row-bg-color);
  padding: var(--seccl-spacing-medium);
  border-top: var(--seccl-table-border);
  border-bottom: var(--seccl-table-border);
}

/* Footer */
.seccl-table-footer-row {
  background-color: var(--seccl-table-footer-bg-color);
  color: var(--seccl-table-footer-color);
  border-top: var(--seccl-table-border);
}

.seccl-table-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  font-size: var(--seccl-table-font-size);
}

/* Header */
.seccl-table-header {
  opacity: 1;
  color: var(--seccl-table-header-color);
  padding: 0;
  border-bottom: var(--seccl-table-border);
}

.seccl-table-header[data-can-sort='true'] {
  cursor: pointer;
}

.seccl-table-header:focus-visible {
  background-color: var(--seccl-table-row-selected-color);
  outline: none;
}

.seccl-table-header[data-is-dragging='true'] {
  cursor: grabbing;
  opacity: 0.8;
}

.seccl-table-header-content {
  display: flex;
  align-items: center;
  padding: var(--seccl-table-cell-padding);
  font-weight: var(--seccl-font-weight-regular);
  border-bottom: 3px solid transparent;
  min-height: 35px;
}

.seccl-table-header[data-is-over='true'] .seccl-table-header-content {
  border-color: var(--seccl-color-brand-secondary);
}

.seccl-table-header-drag-indicator {
  opacity: 0;
  transition: opacity 0.1s;
}

.seccl-table-header:hover .seccl-table-header-drag-indicator {
  opacity: 1;
}

.seccl-table-sort-icon {
  margin-left: var(--seccl-spacing-xsmall);
}

.seccl-table-header-content-start {
  display: flex;
  align-items: center;
  flex-grow: 1;
  font-weight: var(--seccl-font-weight-regular);
}

/* Pagination */
.seccl-table-pagination-toolbar {
  min-height: unset;
  font-size: var(--seccl-table-font-size);
}

.seccl-table-pagination-display-rows {
  margin: 0;
  font-size: var(--seccl-table-font-size);
}

.seccl-table-pagination-select-label {
  font-size: var(--seccl-table-font-size);
}

.seccl-table-rows-selected-label {
  margin-right: var(--seccl-spacing-large);
}

/* Toolbar */
.seccl-table-toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--seccl-spacing-medium) 0;
  gap: var(--seccl-spacing-large);
}

.seccl-table-toolbar-content {
  display: flex;
  gap: var(--seccl-spacing-large);
  overflow-x: auto;
}

.seccl-table-toolbar-inner {
  overflow-x: auto;
  display: flex;
  gap: var(--seccl-spacing-large);
}

.seccl-table-toolbar-actions {
  display: flex;
  align-items: center;
  gap: var(--seccl-spacing-xsmall);
}
:root {
  --seccl-tabs-base-color: var(--seccl-color-grey-4);
  --seccl-tabs-selected-color: var(--seccl-color-accent);
  --seccl-tabs-selected-color-light: var(--seccl-color-accent-light);
  --seccl-tabs-border-bottom-color: var(--seccl-color-grey-2);
  --seccl-tabs-padding: var(--seccl-spacing-medium);
  --seccl-tabs-background-color: transparent;
  --seccl-tab-font-size: var(--seccl-font-size-small);
  --seccl-tab-padding: var(--seccl-spacing-medium) var(--seccl-tabs-padding);
}

.seccl-tabs {
  background-color: var(--seccl-tabs-background-color);
}

.seccl-tabs-tabbar {
  background-color: var(--seccl-color-white);
}

.seccl-tabs-tabbar.seccl-tabs-mobile,
.seccl-tabs-tabbar .MuiTabs-flexContainer {
  border-bottom: 1px solid var(--seccl-tabs-border-bottom-color);
}

.seccl-tabs-mobile {
  width: 100%;
  box-sizing: border-box;
  padding: var(--seccl-tabs-padding);
}

.seccl-tabs-mobile .seccl-input-form-control {
  margin: 0;
  width: 100%;
}

.seccl-tabs-mobile .seccl-input-form-control .seccl-input-label {
  display: none;
  margin: 0;
}

.seccl-tabs-tabbar .MuiTabs-flexContainer {
  padding: 0 var(--seccl-tabs-padding);
}

.seccl-tabs-tabbar .MuiTabs-indicator {
  background-color: var(--seccl-tabs-selected-color);
}

.seccl-tabs-tab {
  color: var(--seccl-tabs-base-color);
  text-transform: none;
  padding: var(--seccl-tab-padding);
  min-width: unset;
  font-size: var(--seccl-tab-font-size);
  transition: color 0.2s, background-color 0.2s;
}

.seccl-tabs-tab:hover {
  color: var(--seccl-tabs-selected-color);
}

.seccl-tabs-tab:focus-visible {
  color: var(--seccl-tabs-selected-color);
  background-color: var(--seccl-tabs-selected-color-light);
}

.seccl-tabs-tab:not(:first-child) {
  margin-left: var(--seccl-spacing-xxxsmall);
}

.seccl-tabs-tab.Mui-selected {
  color: var(--seccl-tabs-selected-color);
  border-bottom-color: var(--seccl-tabs-selected-color);
}
